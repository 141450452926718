import type * as React from 'react';
export const SpeedometerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={11} cy={14} r={2} fill="currentColor" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m11 14 3-5"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M19.3228 15.9631c.5633-1.3178.7803-2.749.6318-4.1669-.1485-1.4178-.6578-2.7786-1.4829-3.9616-.8251-1.183-1.9405-2.152-3.2474-2.8208-1.3069-.6688-2.765-1.017-4.245-1.0138-1.48.0033-2.9365.358-4.2401 1.0326-1.3037.6747-2.4145 1.6485-3.234 2.8352-.8194 1.1867-1.3222 2.5497-1.4639 3.9681-.1417 1.4185.0822 2.8488.6517 4.1641"
    />
  </svg>
);
