import type * as React from 'react';
export const SafeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={16}
      height={15}
      x={3}
      y={3}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={3}
    />
    <path fill="currentColor" d="M16 7h3v2h-3zM16 12h3v2h-3z" />
    <rect width={3} height={3} x={5} y={17} fill="currentColor" rx={1} />
    <rect width={3} height={3} x={14} y={17} fill="currentColor" rx={1} />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.7071 8.2071-.6278.6278A3.4847 3.4847 0 0 1 13.5 10.5a3.4847 3.4847 0 0 1-.4207 1.6651l.6278.6278c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0l-.6278-.6278A3.4847 3.4847 0 0 1 10 14c-.6028 0-1.17-.1524-1.6651-.4207l-.6278.6278c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142l.6278-.6278A3.4844 3.4844 0 0 1 6.5 10.5c0-.6028.1524-1.17.4207-1.6651l-.6278-.6278c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0l.6278.6278A3.4845 3.4845 0 0 1 10 7c.6028 0 1.1699.1524 1.6651.4207l.6278-.6278c.3905-.3905 1.0237-.3905 1.4142 0 .3905.3905.3905 1.0237 0 1.4142ZM10 12c.8284 0 1.5-.6716 1.5-1.5S10.8284 9 10 9s-1.5.6716-1.5 1.5S9.1716 12 10 12Z"
      clipRule="evenodd"
    />
  </svg>
);
