import type * as React from 'react';
export const ParasolHolidayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m12.3418 12.2327 6 6M6.2442 6.134l-.707-.7072M18.1054 4.0785c.4913.3113.5146.9918.1033 1.4031L5.5904 18.1c-.4113.4113-1.0918.388-1.403-.1033C2.0208 14.577 2.7876 9.589 6.2426 6.1339c3.455-3.455 8.4431-4.2218 11.8627-2.0554Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.7828 13.9074c-1.193-1.414-2.0805-2.8198-2.6043-4.042-.3543-.8267-.5211-1.5207-.5359-2.0484-.0146-.5248.1193-.7882.2528-.9216.1334-.1335.3968-.2674.9216-.2528.5277.0147 1.2217.1816 2.0484.5359 1.2222.5238 2.628 1.4114 4.0421 2.6043l1.4188-1.4188c-1.5803-1.352-3.1997-2.3924-4.673-3.0238-.9648-.4135-1.9158-.6727-2.7805-.6968-.8676-.0242-1.744.1902-2.3916.8378s-.862 1.524-.8378 2.3916c.0241.8647.2833 1.8157.6968 2.7805.6314 1.4732 1.6718 3.0927 3.0238 4.673l1.4188-1.4189Z"
      clipRule="evenodd"
    />
  </svg>
);
