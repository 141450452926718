import type * as React from 'react';
export const FoodCutleryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 1c.5523 0 1 .4477 1 1v4a3 3 0 1 0 6 0V2c0-.5523.4477-1 1-1s1 .4477 1 1v4a5 5 0 0 1-4 4.899V20c0 .5523-.4477 1-1 1s-1-.4477-1-1v-9.101A5.0005 5.0005 0 0 1 1 6V2c0-.5523.4477-1 1-1Zm5 1c0-.5523-.4477-1-1-1s-1 .4477-1 1v4c0 .5522.4477 1 1 1s1-.4478 1-1V2Zm8-1a1 1 0 0 0-1 1v10.9775a1.0465 1.0465 0 0 0 0 .0434V20c0 .5523.4477 1 1 1s1-.4477 1-1v-6.2708c2.3864-.9285 4-3.2383 4-5.8523v-1.877a5 5 0 0 0-5-5Zm3 6.8769c0 1.5013-.781 2.8554-2 3.623V3.1715A3 3 0 0 1 18 6v1.877Z"
      clipRule="evenodd"
    />
  </svg>
);
