import type * as React from 'react';
export const RocketFoundingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M18.5639 2.525c-1.1309-.0676-3.399-.0147-5.2133 1.219-2.7861 1.8946-6.1074 8.0348-6.1074 8.0348l2.8481 2.848s6.1402-3.3212 8.0348-6.1073c1.2337-1.8144 1.2866-4.0824 1.219-5.2134-.0255-.4263-.3549-.7556-.7812-.7811Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M9.728 14.2635v4.2426s3.8362-1.0078 4.8613-2.0329c1.0251-1.0252.9767-2.7356-.108-3.8204M7.6066 12.1421H3.364s1.0078-3.8362 2.0329-4.8613c1.0251-1.0252 2.7356-.9768 3.8203.108"
    />
    <path
      fill="currentColor"
      d="M6.3172 19.0884c-.7214.7214-2.5196 1.0063-3.6768 1.117-.5677.0543-1.0301-.4081-.9758-.9757.1108-1.1572.3956-2.9554 1.117-3.6768 1.1027-1.1027 2.788-1.2051 3.7643-.2288.9764.9763.874 2.6616-.2287 3.7643Z"
    />
  </svg>
);
