import type * as React from 'react';
export const ArrowsBothWaysInOneIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 7 4 4-4 4M7 7l-4 4 4 4M18 11H4"
    />
  </svg>
);
